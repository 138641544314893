import React, {useEffect, useState} from 'react';
import fetchFromAPI from 'tools/fetchFromAPI';
import dynamic from 'next/dynamic';
import {useRouter} from "next/router";
import SearchPage from "../components/page/search/SearchPage";

// Page types
const DynamicCategory = dynamic(() => import("../components/page/category"));
const DynamicCMS = dynamic(() => import("../components/page/cms"));
const DynamicProduct = dynamic(() => import("../components/page/product"));
const DynamicError = dynamic(() => import("../components/page/404"));
let isPop = false;

function Page(props) {
	const router = useRouter();
	const [state, setState] = useState(props);
	const [loading, setLoading] = useState(false);
	let {pageData, appData} = state;

	useEffect(() => {
		const handleStart = (url) => {
			console.log(`Loading: ${url}`);
			setLoading(true);
		};

		const handleStop = () => {
			console.log('loading done');
			setLoading(false);
		};

		window.addEventListener('trigger-page-refresh', triggerPageRefresh);
		router.events.on('routeChangeStart', handleStart);
		router.events.on('routeChangeComplete', handleStop);
		router.events.on('routeChangeError', handleStop);

		return () => {
			window.removeEventListener('trigger-page-refresh', triggerPageRefresh);
			router.events.off('routeChangeStart', handleStart);
			router.events.off('routeChangeComplete', handleStop);
			router.events.off('routeChangeError', handleStop);
		};
	}, [router]);

	useEffect(() => {
		if(!loading) {
			let event = document.createEvent("HTMLEvents");
			event.initEvent("pageLoaded", true, true);
			event.eventName = "pageLoaded";
			window.dispatchEvent(event);
		}
	}, [router.asPath, loading]);

	useEffect(() => {
		setState(props);
	}, [props]);

	async function triggerPageRefresh() {
		window.location.href = router.asPath;
	}

	function renderPageType(page) {
		if(!page.hasOwnProperty('type') && loading) {
			return '';
		}

		switch (page.type) {
			case 'category':
				return <DynamicCategory {...page} appData={appData} isLoading={loading}/>;
			case 'product':
				return <DynamicProduct {...page} appData={appData} isLoading={loading}/>
			case 'cms':
				return <DynamicCMS {...page} appData={appData} isLoading={loading}/>
			case 'search':
				return <SearchPage/>
			case 'error':
			case 'no-route':
			default:
				let statusCode = 404;

				return <DynamicError {...page} statusCode={statusCode} isLoading={loading}/>
		}
	}

	return (
		<>
			{renderPageType(pageData)}
		</>
	);
}

async function getPossibleRedirect(url, referer) {
	const controller = new AbortController();
	const id = setTimeout(() => controller.abort(), 2000);

	let hasRedirect = await fetch('https://my.newport.se/api/fourofour', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ url, referer }),
		signal: controller.signal
	});

	clearTimeout(id);

	return await hasRedirect.json();
}

Page.getInitialProps = async (options) => {
	let page = await fetchFromAPI(options);

	try {
		if(options.res && page.pageData.type === 'no-route') {
			let url = page.appData?.meta?.basePath + options.asPath;
			url = url.replaceAll('//', '/').replace('https:/','https://');
			let referer = options.req.header('referer');

			let redirectJson = await getPossibleRedirect(url, referer);

			if (redirectJson.result === 'REDIRECT' && redirectJson.destination) {
				options.res.writeHead(redirectJson.redirect_type, {
					'Location': redirectJson.destination,
					'Content-Type': 'text/html; charset=utf-8'
				});

				options.res.end();
			} else {
				options.res.statusCode = 404;
			}
		} else if(page.pageData.type === 'no-route') {
			let url = window.location.origin + options.asPath;
			let redirectJson = await getPossibleRedirect(url);

			if(redirectJson.result === 'REDIRECT' && redirectJson.destination) {
				window.location = redirectJson.destination;
			}
		}
	} catch (e) {
		console.log(e);
	}

	return {...page, namespacesRequired: ['general', 'attributes', 'errors', 'product-info']};
}

if(typeof window !== 'undefined') {
	window.addEventListener('popstate', () => {
		isPop = true;
	});
}

export default Page;
